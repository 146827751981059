import React from 'react'
import ServiceCard from '../Components/Servicecard'
import socialmkt from '../images/assets/socialmkt.png'
import dash from '../images/assets/dash.svg'
import mkt1 from '../images/assets/mkt1.svg'
import mkt2 from '../images/assets/mkt2.svg'
import mkt3 from '../images/assets/mkt3.svg'
import mkt4 from '../images/assets/mkt4.svg'
import mkt5 from '../images/assets/mkt5.svg'
import mkt6 from '../images/assets/mkt6.svg'
import mkt7 from '../images/assets/mkt7.svg'
import mkt8 from '../images/assets/mkt8.svg'
import mkt9 from '../images/assets/mkt9.svg'
import port from '../images/assets/port.png'
import cons from '../images/assets/cons.png'
const SMM = () => {
  return (
    <>
      <section id='header' className='d-flex align-items-center'>
                <div className='service1'>
                    <img src={socialmkt} className='branding' style={{position: 'relative',zIndex: '-10'}}/>
                </div>
            </section>
            <div className='service2'>
                <div className='service2txt'>
                    <h1>Creating Brands that Resonate</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
                    <p>Branding is not just a logo or tagline; it's an identity. It is proven by the fact that brand awareness is the top goal for 89% of marketers. Trust in a brand encourages 81% of consumers to purchase, and consistent branding can increase revenues by 23%.</p>
                </div>
                <div className="service2con">
      <div className="condiv">
        <ServiceCard
          imageSrc={mkt1}
          h1="Social Media Strategy"
          p="We aim to align this strategy with your broader business objectives, making your social media presence a strategic asset."
        />
        <ServiceCard
          imageSrc={mkt4}
          h1="Social Media Advertising"
          p="We strategically leverage the promotional capabilities of different social platforms to increase brand awareness and promote your products or services."
        />
        <ServiceCard
          imageSrc={mkt7}
          h1="Social Listening & Monitoring"
          p="Wurfel IT employs social listening tools to monitor and analyze conversations about your brand across social media."
        />
      </div>
      <div className="condiv">
        <ServiceCard
          imageSrc={mkt2}
          h1="Content Creation & Curation"
          p="Our creative team develops engaging, relevant, and unique content tailored to your brand’s personality and your audience's preferences."
        />
        <ServiceCard
          imageSrc={mkt5}
          h1="Audience Engagement"
          p="We handle all forms of engagement on your social media platforms, sparking conversations, responding to comments, and managing your online community."
        />
        <ServiceCard
          imageSrc={mkt8}
          h1="Reputation Management"
          p="We do this through regular monitoring and swift, professional responses to both positive and negative customer feedback."
        />
      </div>
      <div className="condiv">
        <ServiceCard
          imageSrc={mkt3}
          h1="Social Media Management"
          p="Wurfel IT simplifies this process by managing all your social media accounts, ensuring consistent posting, and timely response to comments."
        />
        <ServiceCard
          imageSrc={mkt6}
          h1="Influencer Marketing"
          p="Our team at Wurfel IT is experienced in identifying, reaching out, and negotiating with influencers that resonate with your brand and target demographic."
        />
        <ServiceCard
          imageSrc={mkt9}
          h1="Social Media Analytics & Reporting"
          p="We provide insights into audience growth, engagement, reach, and conversions, aiding in strategy adjustments and future planning."
        />
      </div>
    </div>      
    </div>  
            <div className='service3'>
                <div className='service3txt'>
                    <h1>Connect with Wurfel IT today and let's shape your social media success story.</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
                    <p>We believe that social media marketing is not a one-size-fits-all approach. That's why we offer customized solutions, tailored to your specific needs and industry. Whether you're a startup looking for growth or an established brand aiming for greater reach and engagement, Wurfel IT is here to make your social media presence a significant factor in your success.
                        <br />
                        <br />
                        Join us, and let's start the journey to your social media success today!</p>
                </div>
                <div className='request'>
                <button className='req'>Check our portfolio <img src={port} className='reqimg' /></button>
                <button className='req'>Free Consultation <img src={cons} className='reqimg' /></button>
            </div>
            </div>
            
    </>
  )
}

export default SMM

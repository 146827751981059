import React from 'react'
import dash from '../images/assets/dash.svg'
import grp1 from '../images/assets/grp1.png'
import img1 from '../images/assets/img1.png'
import img2 from '../images/assets/img2.png'
import img3 from '../images/assets/img3.png'
import img4 from '../images/assets/img4.png'
import cons from '../images/assets/cons.png'
import port from '../images/assets/port.png'
import ceo from '../images/assets/CEO.png'
import dr from '../images/assets/DR.png'
const About = () => {
  return (
    <>
      <section id='header'>
        <div className='about-wrapper'>
          <div className='content'>
            <h1><span className='firstline'>Envisioning</span><br></br><span style={{ color: 'black' }}>a Digital Future</span></h1>
            <p>Merging Tradition and Innovation for&nbsp;<b>Global IT Excellence!</b></p> </div>
          <div className='header-img-container'> <img src={grp1} className='grp1' alt='' /> </div>
        </div>
      </section>
      <div className='aboutceo1' id='leadership'>
        <div className="ceomain">
          <div><img src={ceo} className="about1img" alt='' /></div>
          <div className="ceomaintxt">
            <h1>Sheikh Abdul Qadir</h1>
            <h2>CEO</h2>
            <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px' }} alt='' />
            <p>Sheikh Abdul Qadir is an entrepreneurial brand strategist, embodying the powerful fusion of business acuity and creative genius. Commanding an intimate understanding of the corporate and inventive facets of business, Qadir transcends traditional roles, embodying both the architect of ideas and the executor of visions within his own enterprise. Navigating the complexities of business, he identifies market opportunities while resonating with the aesthetics of creative thinking, expertly merging the analytical rigor of business strategy with the ingenuity of creative narratives. He not only crafts but also orchestrates the entire lifecycle of a brand's journey from the birth of an idea to its flawless execution. His dual proficiency allows him to connect and communicate effectively with his audience, bridging the gap between a brand's promise and the consumers' expectations. With this holistic approach, Sheikh Abdul Qadir breathes life into brand visions, leading his enterprise to success from ideation to realization.</p>
          </div>
        </div>
      </div>
      <div className='aboutceo2'>
        <div className="ceomain">
          <div className="ceomaintxt">
            <h1>Asad Ullah Shabir</h1>
            <h2>Director</h2>
            <img src={dash} className='dash' style={{ width: '68px' }} alt='' />
            <p>A true industry enthusiast, Asad never misses a beat when it comes to staying updated with the latest trends and technologies in the digital space. He believes in a leadership style that promotes collaboration and innovation, cultivating a vibrant, growth-oriented work culture. His dedication and passion are also reflected in his mentorship of emerging creative talents, nurturing their development and encouraging their creative explorations. Asad Ullah Shabbir holds a pivotal role as our Director, a position finely honed through years of enriched industry experience and forward-thinking innovation. His expansive career path has been marked by a unique blend of creative vision, strategic acumen, and profound technological expertise. Known for his ability to effortlessly merge innovation and practicality, Asad has been instrumental in transforming diverse businesses, leading them into the digital era with a suite of engaging, efficient, and unique solutions.</p>
          </div>

          <div ><img src={dr} className="about1img" alt='' /></div>
        </div>
      </div>
      <div className='aboutcon'>
        <div className='aboutcontxt'>
          <h1>Who We Are</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px' }} alt='' />
          <h3>Born in the heart of Islamabad, Pakistan, we were officially incorporated in 2017. Now, we stand proud as a private limited company, registered under the prestigious Securities & Exchange Commission of Pakistan (SECP). We are more than an IT firm. We are a symphony of experience and innovation, a blend of tradition and trends, a balance of performance and customer satisfaction. Our passion is driving sustainable change in the quality of IT services, revolutionizing business landscapes across the globe. </h3>
        </div>
        <div className='aboutdata'>
          <div className='comps'>
            <div className='aboutcards'>
              <h1>Our Core Values</h1>
              <p>At Wurfel IT, our values are the lifeblood that courses through our company veins. Imagine the spirit of pioneering leadership as the fiery, courageous torchbearer illuminating our path forward. We understand that great things are achieved through teamwork.</p>
            </div>
            <div>
              <img src={img2} className='aboutimg' alt='' />
            </div>
            <div className='aboutcards'>
              <h1>Our Focus</h1>
              <p>We live and breathe the market. We understand that the needs of our consumers and customers are dynamic, evolving with each passing day. That's why we keep our ears to the ground, observing, listening, and learning.</p>
            </div>
            <div>
              <img src={img4} className='aboutimg' alt='' />
            </div>
          </div>
          <div className='dots'>
            <h1>01</h1>
            <p>----------------------------</p>
            <h1>02</h1>
            <p>----------------------------</p>
            <h1>03</h1>
            <p>----------------------------</p>
            <h1>04</h1>
            <p>----------------------</p>
          </div>
          <div className='comps'>
            <div>
              <img src={img1} className='aboutimg' alt='' />
            </div>
            <div className='aboutcards'>
              <h1>Our Core Values</h1>
              <p>At Wurfel IT, our values are the lifeblood that courses through our company veins. Imagine the spirit of pioneering leadership as the fiery, courageous torchbearer illuminating our path forward. We understand that great things are achieved through teamwork.</p>
            </div>
            <div>
              <img src={img3} className='aboutimg' alt='' />
            </div>
            <div className='aboutcards'>
              <h1>Our Focus</h1>
              <p>We live and breathe the market. We understand that the needs of our consumers and customers are dynamic, evolving with each passing day. That's why we keep our ears to the ground, observing, listening, and learning.</p>
            </div>
          </div>
        </div>




        <div className='aboutdata-mob'>
          <div className='aboutmobcards-main'>
            <h1>01</h1>
            <div className='aboutmobcards'>
              <h1>Our Core Values</h1>
              <p>At Wurfel IT, our values are the lifeblood that courses through our company veins. Imagine the spirit of pioneering leadership as the fiery, courageous torchbearer illuminating our path forward. We understand that great things are achieved through teamwork.</p>
            </div>
            <div>
              <img src={img1} className='aboutimg' alt='' />
            </div>
          </div>
          <div className='aboutmobcards-main'>
            <h1>02</h1>
            <img src={img2} className='aboutimg' alt='' />
            <div className='aboutmobcards'>
              <h1>Our Core Values</h1>
              <p>At Wurfel IT, our values are the lifeblood that courses through our company veins. Imagine the spirit of pioneering leadership as the fiery, courageous torchbearer illuminating our path forward. We understand that great things are achieved through teamwork.</p>
            </div>
          </div>
          <div className='aboutmobcards-main'>
            <h1>03</h1>
            <img src={img3} className='aboutimg' alt='' />
            <div className='aboutmobcards'>
              <h1>Our Core Values</h1>
              <p>At Wurfel IT, our values are the lifeblood that courses through our company veins. Imagine the spirit of pioneering leadership as the fiery, courageous torchbearer illuminating our path forward. We understand that great things are achieved through teamwork.</p>
            </div>
          </div>
          <div className='aboutmobcards-main'>
            <h1>04</h1>
            <img src={img4} className='aboutimg' alt='' />
            <div className='aboutmobcards'>
              <h1>Our Core Values</h1>
              <p>At Wurfel IT, our values are the lifeblood that courses through our company veins. Imagine the spirit of pioneering leadership as the fiery, courageous torchbearer illuminating our path forward. We understand that great things are achieved through teamwork.</p>
            </div>
          </div>
        </div>
      </div>
      <div className='brand'>
        <h1>Every Brand is Unique. So Are Our Solutions</h1>
        <img src={dash} className='dash' style={{ marginTop: '20px', width: '68px' }} alt='' />
        <h3>We specialize in various types of branding, each with unique advantages. These include corporate branding for broad appeal, product branding for distinct identities, personal branding for individuals, service branding emphasizing quality, and cultural branding resonating with specific groups. </h3>
        <div className='request'>
          <a href="/Work"> <button className='req'>Check our portfolio <img src={port} className='reqimg' alt='' /></button></a>
          <a href="/Contact"> <button className='req'>Free Consultation <img src={cons} className='reqimg' alt='' /></button></a>
        </div>
      </div>
    </>
  )
}

export default About;
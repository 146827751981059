import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import img from '../images/cube.png'
import dash from '../images/assets/dash.svg'
import Compart1 from '../Components/Compart1';
import Cardcompmob from '../Components/Cardcompmob'
import req from '../images/assets/req.png'
import cons from '../images/assets/cons.png'
import FAQ from '../Components/FAQ'
import NumberShuffler from '../Components/NumberShuffler'
import stars from '../images/assets/stars.png'
import LogoSlider from '../Components/Logoslider'
const Home = () => {
  const RevCards = ({ revText, heading, subheading}) => {
    return (
      <div className="revcards">
        <div>
          <img className="stars" src={stars} alt="Stars" style={{ marginTop: '52px' }} />
        </div>
        <p className="revtext">{revText}</p>
        <div className="info">
          <h1>{heading}</h1>
          <p>{subheading}</p>
        </div>
      </div>
    );
  };

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
      <section id='header'>
        <div className='homepage-wrapper'>
          <div className='content'>
            <h1><span className='firstline'>Unbox Your</span><br></br><span style={{ color: 'black !important' }}>Corporate edge</span></h1>
            <p className='my-3'>Because Big Visions Need Bigger  &nbsp; <b>Solutions!</b></p>
            <div className='mt-3 d-flex'>
             <a href="/About"><button to='' className='btn-more-info'>More Info&nbsp; <span className='moreinfoarrow'>&#8594;</span> </button></a> 
            </div>
          </div>
          <div className='header-img'>
            <img src={img} className='image-fluid animated' alt=''/>
          </div>
          <div className='selection'>
            <div className='ellipse selected'></div>
            <div className='ellipse'></div>
            <div className='ellipse'></div>
          </div>
        </div>
      </section>
      <div className='agency'>
        <div className='Agency-tag'>
          <h2>Wurfel IT - Shaping a Dynamic Business Landscape</h2>
          <img src={dash} className='dash' style={{ marginTop: '20px' }} alt=''/>
          <h3>In the new age of digital connection, your online presence is more than a luxury, it's a necessity. At Wurfel IT, we transform your dreams into tangible digital realities. From dynamic website development to cutting-edge social media marketing, we aren't just shaping your brand - we're shaping the digital landscape. </h3>
        </div>
        <Compart1/>
        <Cardcompmob />
      </div>
      <div className='powering'>
        <NumberShuffler />
        <div className='request'>
          <button className='req'>Request a Qoute <img src={req} className='reqimg' alt=''/></button>
          <a href="/Contact"><button className='req'>Free Consultation <img src={cons} className='reqimg' alt=''/></button></a>
        </div>
      </div>

      <LogoSlider />


      <div className='FAQs'>
        <div className='faqtxt'>
          <h1>FAQs</h1>
          <p>Have Questions? We're Here to Help</p>
        </div>
        <div className='questions'>
          <FAQ />
        </div>
      </div>
      <div className="carousel" id='reviews'>
        <div className='reviewcon'>
          <h1>What do our clients have to say about us?</h1>
          <h2>4.8 out of 5.00 ratings on 300 reviews.</h2>
          <img src={dash} className='dash' style={{ marginTop: '24px', marginBottom: '66px' }} alt=''/>
        </div>
        <Slider {...settings}>
          <div className="box">
            <RevCards
              revText="At Wurfel IT, our digital dreams took flight, their all-encompassing expertise reshaped our online presence and boosted our brand visibility."
              heading="Amelia Ciay"
              subheading="Project Manager"
            />
          </div>
          <div className="box">
            <RevCards
              revText="Reduce costs by up to 70% by automating the following inbound calls to the Customer Center."
              heading="Amelia Ciay"
              subheading="Project Manager"
            />
          </div>
          <div className="box">
            <RevCards
              revText="Transforming our social media footprint and perfecting our brand's aesthetic, Wurfel IT brought our company's vision to life in the digital world"
              heading="Amelia Ciay"
              subheading="Project Manager"
            />
          </div>
          <div className="box">
            <RevCards
              revText="Transforming our social media footprint and perfecting our brand's aesthetic, Wurfel IT brought our company's vision to life in the digital world"
              heading="Amelia Ciay"
              subheading="Project Manager"
            />
          </div>
          <div className="box">
            <RevCards
              revText="Transforming our social media footprint and perfecting our brand's aesthetic, Wurfel IT brought our company's vision to life in the digital world"
              heading="Amelia Ciay"
              subheading="Project Manager"
            />
          </div>
        </Slider>
      </div>

    </>
  )
}
export default Home;
import React, { useState, useEffect } from 'react';
import bg from '../images/assets/bg.png'
import trustedcl from '../images/assets/trustedcl.png'
import pjts from '../images/assets/pjts.png'
import exp from '../images/assets/exp.png'
import proteam from '../images/assets/proteam.png'
import dash from '../images/assets/dash.svg'


const NumberShuffler = () => {
  const [numbers, setNumbers] = useState([700, 7800, 0, 0]); // Initialize with initial numbers
  const [stopNumbers, setStopNumbers] = useState([793, 7939, 5, 36]);

  useEffect(() => {
    const interval = setInterval(() => {
      const updatedNumbers = numbers.map((number, index) => {
        if (number < stopNumbers[index]) {
          return number + 1;
        }
        return number;
      });

      setNumbers(updatedNumbers);
    }, 30);

    return () => clearInterval(interval);
  }, [numbers, stopNumbers]);

  return (
    <div className="powering">
      <div className="powering-tag">
        <h1>Powering Businesses with Smart IT Solutions</h1>
        <p>
          In the rapidly evolving tech landscape, your business needs more than
          cookie-cutter IT solutions. Our bespoke IT services are designed to
          adapt and grow with your business, ensuring you're always ahead of
          the curve.
        </p>
        <img src={dash} className="dash" style={{ marginTop: '17px' }} />
      </div>
      <div className="cards">
        <img src={bg} className="bgg" />
        <div className="data">
            <img src={trustedcl} className="dataimg" />
            <h1>{numbers[0]}</h1>
            <p>Trusted Clients</p>
 
        </div>
        <div className="data">

            <img src={pjts} className="dataimg" />
            <h1>{numbers[1]}</h1>
            <p>Projects Completed</p>

        </div>
        <div className="data">
            <img src={exp} className="dataimg" />
            <h1>{numbers[2]}</h1>
            <p>Years of Experience</p>
        </div>
        <div className="data">
          <img src={proteam} className="dataimg" />
          <h1>{numbers[3]}</h1>
          <p>Professional Team</p>
        </div>
      </div>
    </div>
  );
};

export default NumberShuffler;

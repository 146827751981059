// ChatWidget.js
import React, { useState } from 'react';
import Chatbox from '../Components/ChatBox';
import '../../src/style.css'

const ChatWidget = () => {
    const [isChatboxOpen, setChatboxOpen] = useState(false);
  
    const toggleChatbox = () => {
      setChatboxOpen(prevState => !prevState);
    };
  
    return (
      <div className="chat-widget">
        <div className="chat-wrapper" onClick={toggleChatbox}>
          <img
            src={require('../images/assets/c.png')}
            className="chat"
            alt="Chat Icon"
          />
        </div>
        {isChatboxOpen && <Chatbox />}
      </div>
    );
  };


export default ChatWidget;

import React from 'react'
import ServiceCard from '../Components/Servicecard'
import contentmarketing from '../images/assets/contentmarketing.png'
import dash from '../images/assets/dash.svg'
import port from '../images/assets/port.png'
import cons from '../images/assets/cons.png'
import con1 from '../images/assets/con1.svg'
import con2 from '../images/assets/con2.svg'
import con3 from '../images/assets/con3.svg'
import con4 from '../images/assets/con4.svg'
import con5 from '../images/assets/con5.svg'
import con6 from '../images/assets/con6.svg'
import con7 from '../images/assets/con7.svg'
import con8 from '../images/assets/con8.svg'
import con9 from '../images/assets/con9.svg'

const Content = () => {
  return (
    <>
      <section id='header' className='d-flex align-items-center'>
                <div className='service1'>
                    <img src={contentmarketing} className='branding' style={{zIndex: '-10'}} />
                </div>
            </section>
            <div className='service2'>
                <div className='service2txt'>
                    <h1>Creating Brands that Resonate</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
                    <p>Branding is not just a logo or tagline; it's an identity. It is proven by the fact that brand awareness is the top goal for 89% of marketers. Trust in a brand encourages 81% of consumers to purchase, and consistent branding can increase revenues by 23%.</p>
                </div>
                <div className="service2con">
      <div className="condiv">
        <ServiceCard
          imageSrc={con1}
          h1="Strategic Content Planning"
          p="At Wurfel IT, we create tailored content strategies aligned with your brand's objectives to maximize audience engagement and conversions."
        />
        <ServiceCard
          imageSrc={con2}
          h1="Social Media Marketing"
          p="We leverage the power of social media platforms, delivering creative content that boosts your brand's online presence and fosters meaningful customer relationships."
        />
        <ServiceCard
          imageSrc={con3}
          h1="Data Analysis and Reporting"
          p="We provide detailed content performance reports, utilizing data-driven insights to continuously optimize your content marketing strategy for the best results."
        />
      </div>
      <div className="condiv">
        <ServiceCard
          imageSrc={con4}
          h1="Content Creation"
          p="Our seasoned copywriters and designers produce high-quality, SEO-friendly content - from blogs, infographics, to white papers - that resonates with your target audience."
        />
        <ServiceCard
          imageSrc={con5}
          h1="Content Promotion"
          p="Beyond creation, we ensure your content reaches its intended audience through meticulous distribution strategies across relevant digital channels."
        />
        <ServiceCard
          imageSrc={con6}
          h1="Conversion Rate Optimization"
          p="Through rigorous A/B testing and user behavior analysis, we optimize your content to effectively turn site visitors into customers."
        />
      </div>
      <div className="condiv">
        <ServiceCard
          imageSrc={con7}
          h1="SEO Optimization"
          p="We expertly incorporate targeted keywords into your content, helping your brand gain visibility in search results and drive organic traffic to your site."
        />
        <ServiceCard
          imageSrc={con8}
          h1="Email Marketing"
          p="Our team designs compelling email campaigns with personalized content, turning your subscribers into loyal customers and advocates for your brand."
        />
        <ServiceCard
          imageSrc={con9}
          h1="Content Maintenance:"
          p="To keep your content fresh and relevant, we regularly update and repurpose existing content, ensuring it continues to deliver value to your audience."
        />
      </div>
    </div>      
    </div>  
            <div className='service3'>
                <div className='service3txt'>
                    <h1>Ready to supercharge your brand with exceptional content marketing?</h1>
                    <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
                    <p>Don't wait! Join us at Wurfel IT today and let's begin crafting your success story. Contact us now - your digital growth journey starts here!</p>
                </div>
                <div className='request'>
                <button className='req'>Check our portfolio <img src={port} className='reqimg' /></button>
                <button className='req'>Free Consultation <img src={cons} className='reqimg' /></button>
            </div>
            </div>

    </>
  )
}

export default Content

import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import andrew from '../images/assets/andrew.svg'
import arista from '../images/assets/arista.png'
import bluehorizon from '../images/assets/bluehorizon.png'
import uci from '../images/assets/uci.png'
import juicy from '../images/assets/juicy.png'
import terracosta from '../images/assets/terracosta.png'
import falaknaz from '../images/assets/falaknaz.png'
import ibrahimi from '../images/assets/ibrahimi.png'
import dash from '../images/assets/dash.svg'
import slick from '../images/assets/slick.png'
const Logoslider = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 8,
        slidesToScroll: 6,
        initialSlide: 0,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              initialSlide: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
      };
        
  return (
    <>
      <div className="carousel" id='logos'>
            <div className='clientscon'>
                    <h1>Our Happy Clients</h1>
                    <h2>Don't just take our word for it. Join the ranks of successful businesses that have embraced our game-changing solutions.</h2>
                    <img src={dash} className='dash' style={{ marginTop: '24px', marginBottom: '66px'}} />
            </div>
                <Slider {...settings}>
                <div className="box">
                <img src={andrew} className='techlogo' />
                </div>
                <div className="box">
                <img src={arista} className='techlogo' />
                </div>
                <div className="box">
                <img src={bluehorizon} className='techlogo' />
                </div>
                <div className="box">
                <img src={uci} className='techlogo' />
                </div>
                <div className="box">
                <img src={juicy} className='techlogo' />
                </div>
                <div className="box">
                <img src={terracosta} className='techlogo' />
               </div>
               <div className="box">
               <img src={falaknaz} className='techlogo' />
               </div>
               <div className="box">
               <img src={ibrahimi} className='techlogo' />
               </div>
               <div className="box">
               <img src={slick} className='techlogo' />
               </div>
                </Slider>
            </div>
    </>
  )
}

export default Logoslider

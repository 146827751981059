import React from 'react'
import careerimg from '../images/assets/careerimg.png'
import dash from '../images/assets/dash.svg'
import con1 from '../images/assets/con1.png'
import con2 from '../images/assets/con2.png'
import con3 from '../images/assets/con3.png'
import joinus from '../images/assets/joinus.svg'
// import careershd from '../careershd.png'
const Career = () => {
  return (
    <>
      <section id='header'>
        <div className='career-wrapper'>
          <div className='content'>
            <h1><span className='firstline'>Grow With Us!</span><br></br><span style={{ color: 'black' }}>Wurfel IT Careers</span></h1>
            <p className='my-3'>Because Big Visions Need Bigger  &nbsp; <b>Solutions!</b></p>
          </div>
          <div className=' header-img' style={{position: 'relative',zIndex: '-10'}}>
            <img src={careerimg} className='image-fluid animated'  />
            {/* <img src={careershd} className='shd'/> */}
          </div>

        </div>
      </section>
      <div className='career1'>
        <div className='career1txt'>
          <h1>Join us, Ignite your potential, and shape the future of IT</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
          <p>At Wurfel IT, we are constantly scouting for professionals who excel in their fields and bring unique perspectives. If you thrive in a fast-paced, innovation-driven environment, explore our current vacancies.</p>
        </div>
        <div className='career1con'>
          <div className='con1'>
            <h2>Conducitve Work Culture</h2>
            <p>Embrace an opportunity where your ideas matter, and creativity is celebrated. We encourage innovation and reward initiative. We are steadfast in our quest to inspire change and create a digital ecosystem where possibilities are boundless.</p>
          </div>
          <div className='con2'>
            <img src={con1} className='con2img' />
          </div>
        </div>
      </div>
      <div className='career2'>
        <div className='career2txt'>
          <h1>Join a Team That Values You: Let's Grow Together</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
          <p>Your growth is our success. We facilitate continuous learning and development through structured on-the-job training, workshops, and mentoring.</p>
        </div>
        <div className='career2con'>
          <div className='con1'>
            <img src={con2} className='con2img' />
          </div>
          <div className='con2'>
            <h2>Professional Development</h2>
            <p>As part of our family, you are more than an employee - you are a valued team member. We offer a supportive environment where professional growth is nurtured and celebrated. We strive to maintain a work environment where everyone feels valued.</p>
          </div>
        </div>
      </div>
      <div className='career3'>
        <div className='career3txt'>
          <h1>Turn Challenges into Triumphs: Make Your Mark</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
          <p>Experience the future with our avant-garde technological infrastructure. We invest in the best tools and technologies that keep you ahead of the curve.</p>
        </div>
        <div className='career3con'>
          <div className='con1'>
            <h2>A Transformative leap</h2>
            <p>Our culture is what sets us apart and makes Wurfel IT an exciting, rewarding, and fulfilling place to work. We offer a competitive salary structure, complemented by a comprehensive benefits package, recognizing the value you bring to our team.</p>
          </div>
          <div className='con2'>
            <img src={con3} className='con2img' />
          </div>
        </div>
      </div>
      <div className='career4'>
        <div className='career4txt'>
          <h1>Talent Network</h1>
          <img src={dash} className='dash' style={{ marginTop: '20px', marginBottom: '20px', width: '68px' }} />
          <p>Can’t find a role that fits? Join our Talent Network and be the first to know about new opportunities matching your skill set.
              <br/>
              <br />
            Should you require any additional information or assistance during the application process, please do not hesitate to connect with our HR team. We look forward to your participation in the growth narrative of Wurfel IT.
          </p>
        </div>
        <div className='joinus'>
                <a href="https://www.linkedin.com/company/wurfelit/"><button className='join'>Join Us <img src={joinus} className='reqimg' /></button></a>
            </div>
      </div>
    </>
  )
}

export default Career;
import React from 'react';
import XMLID from '../images/assets/XMLID_356_.png'
import vector1 from '../images/assets/vector1.png'
import coding1 from '../images/assets/coding1.png'
import smm from '../images/assets/smm.png'
import seo from '../images/assets/seo.png'
import Slider from 'react-slick';
const Group = ({ heading, text, imageSrc, url }) => {

    return (
        <div className='section-cards-mob'>
            <div className="group">
                <a href={url} className="div-a">
                    <div className="iconn">
                        <img className="XMLID" alt={heading} src={imageSrc} />
                    </div>
                    <div className="heading">{heading}</div>
                    <h4 className="in-a-world">{text}</h4>
                    <div className="grp">
                        <div className="View-more">View More &nbsp; <span>&#8594;</span></div>
                    </div>
                </a>
            </div>
        </div>
    );
};

const Compart1 = () => {
    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1

    };
    return (
        <>

            <div className="mob-cards">
                <Slider {...settings}>
                    <Group
                        heading="Branding"
                        text="In a world full of technology where we have a buyer-controlled marketplace one needs to st."
                        imageSrc={XMLID}
                        url=''
                    />
                    <Group
                        heading="Graphic Designing"
                        text="In a world full of technology where we have a buyer-controlled marketplace one needs to st."
                        imageSrc={vector1}
                        url=''
                    />
                    <Group
                        heading="Website Development"
                        text="In a world full of technology where we have a buyer-controlled marketplace one needs to st."
                        imageSrc={coding1}
                        url=''
                    />
                    <Group
                        heading="Social Media Marketing"
                        text="In a world full of technology where we have a buyer-controlled marketplace one needs to st."
                        imageSrc={smm}
                        url=''
                    />
                    <Group
                        heading="SEO"
                        text="In a world full of technology where we have a buyer-controlled marketplace one needs to st."
                        imageSrc={seo}
                        url=''
                    />
                </Slider>

            </div>

        </>
    );
};

export default Compart1;

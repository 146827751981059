import React from 'react'
import Home from '../src/Pages/Home'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle';
import Navbar from '../src/Components/Navbar';
import Footer from '../src/Components/Footer';
import { Route, Routes } from 'react-router-dom/dist/umd/react-router-dom.development';
import Services from '../src/Pages/Services';
import Work from '../src/Pages/Work';
import About from '../src/Pages/About';
import Career from '../src/Pages/Career';
import Contact from '../src/Pages/Contact';
import Branding from '../src/Pages/Branding';
import WebDevelopment from '../src/Pages/WebDevelopment';
import SMM from '../src/Pages/SMM';
import Graphic from '../src/Pages/Graphic';
import Content from '../src/Pages/Content';
import ChatWidget from '../src/Components/ChatWidget';
import Blogs from '../src/Pages/Blogs';
import Terms from './Components/Terms & Privacy/Terms';
import Privacy from './Components/Terms & Privacy/Privacy'
const App = () => {
  
  return (
    <>
      <Navbar/>
      <ChatWidget/>
      <Routes>
      <Route exact path='/' element={<Home/>} />
      <Route exact path='/About' element={<About/>} />
      <Route exact path='/Services' element={<Services/>} />
      <Route exact path='/Work' element={<Work/>} />
      <Route exact path='/Career' element={<Career/>} />
      <Route exact path='/Contact' element={<Contact/>} />
      <Route exact path='/Branding' element={<Branding/>}/>
      <Route exact path='/WebDevelopment' element={<WebDevelopment/>}/>
      <Route exact path='/SMM' element={<SMM/>}/>
      <Route exact path='/Graphic' element={<Graphic/>}/>
      <Route exact path='/Content' element={<Content/>}/>
      <Route exact path='/Blogs' element={<Blogs/>}/>
      <Route exact path='/Terms' element={<Terms/>}/>
      <Route exact path='/Privacy' element={<Privacy/>}/>
      </Routes>
      <Footer/>
    </>  
  )
}

export default App;


import React, { useEffect, useState } from 'react'
import fb from '../images/assets/fb.png'
import ig from '../images/assets/ig.png'
import yt from '../images/assets/yt.png'
import lin from '../images/assets/lin.png'
import footerlogo from '../images/assets/footerlogo.png'
import enddash from '../images/assets/enddash.png'
import { useLocation } from 'react-router-dom'

const Footer = () => {
    const [visibleSection, setVisibleSection] = useState(null);

    const toggleVisibility = (section) => {
        setVisibleSection(section === visibleSection ? null : section);
    };
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.getElementById(location.hash.replace('#', ''));
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [location.hash]);
    return (
        <>
            <div className='footerMain'>
                <div className='footer'>
                    <div className='footerTxt'>
                        <div className='company' style={{ lineHeight: '8' }}>
                            <h1><b>Company</b></h1>
                            <p><a href='/About' >About Wurfel</a></p>
                            <p><a href='/Career' >Careers At Wurfel</a></p>
                            <p>Testimonials</p>
                            <p><a href='/Contact' >Contact Us</a></p>
                        </div>
                        <div className='community' style={{ width: '288px' }}>
                            <h1><b>Community</b></h1>
                            <p><a href='/About#leadership'>Leadership</a></p>
                            <p><a href='/Blogs' >Blogs & Events</a></p>
                        </div>
                        <div className='services'>
                            <h1><b>Sevices</b></h1>
                            <p><a href="/WebDevelopment" >Website Development</a></p>
                            <p><a href="/Branding" >Branding</a></p>
                            <p><a href="/Graphic" >Graphic Designing</a></p>
                            <p><a href="/Content" >Content Marketing</a></p>
                            <p><a href="/SMM" >Social Media Marketing</a></p>
                        </div>
                    </div>
                    <div className='footerTxt-mob'>
                        <div className='company' style={{ lineHeight: '8' }}>
                            <h1 onClick={() => toggleVisibility('company')}><b>Company</b></h1>
                            {visibleSection === 'company' && (
                                <>
                                    <p><a href='/About'>About Wurfel</a></p>
                                    <p><a href='/Career'>Careers At Wurfel</a></p>
                                    <p>Testimonials</p>
                                    <p><a href='/Contact'>Contact Us</a></p>
                                </>
                            )}
                        </div>
                        <div className='community' style={{ width: '288px' }}>
                            <h1 onClick={() => toggleVisibility('community')}><b>Community</b></h1>
                            {visibleSection === 'community' && (
                                <>
                                    <p><a href='/About#leadership'>Leadership</a></p>
                                    <p><a href='/Blogs'>Blogs & Events</a></p>
                                </>
                            )}
                        </div>
                        <div className='services'>
                            <h1 onClick={() => toggleVisibility('services')}><b>Services</b></h1>
                            {visibleSection === 'services' && (
                                <>
                                    <p><a href="/WebDevelopment">Website Development</a></p>
                                    <p><a href="/Branding">Branding</a></p>
                                    <p><a href="/Graphic">Graphic Designing</a></p>
                                    <p><a href="/Content">Content Marketing</a></p>
                                    <p><a href="/SMM">Social Media Marketing</a></p>
                                </>
                            )}
                        </div>
                    </div>
                    <div className='footerInfo'>
                        <div className='phone'>
                            <h2><b>Phone no:</b><br></br><a href="tel:+44 7771 743077" >+447771743077</a></h2>
                        </div>
                        <div className='address' style={{ width: '233px' }}>
                            <h2><b>Address:</b><br></br><a href="https://goo.gl/maps/VCWJ7Bz2ewFoRqJL6" >12 Delane Dr, Winnersh, Wokingham RG41 5AT, UK</a></h2>
                        </div>
                        <div className='email'>
                            <h2><b>Email:</b><br></br><a href="mailto:info@wurfelit.com">info@wurfelit.com</a><br></br></h2>
                        </div>

                    </div>
                </div>
                <div className='icons'>
                    <div>
                        <a href={'/'}> <img src={footerlogo} alt='' /></a>
                    </div>
                    <div className='imgs'>
                        <a href="https://www.facebook.com/wurfelIT"><img src={fb} /></a>
                        <a href="https://instagram.com/wurfelit?igshid=MzRlODBiNWFlZA=="><img src={ig} /></a>
                        <a href="https://instagram.com/wurfelit?igshid=MzRlODBiNWFlZA=="><img src={yt} /></a>
                        <a href="https://www.linkedin.com/company/wurfelit/"><img src={lin} /></a>
                    </div>
                </div>
                <div className='dash-line' />
                <div className='endTxt'>
                    <h3><a href="/Terms">Terms and Conditions </a> | <a href="/Privacy">Privacy Policy</a></h3>
                    <h3>Copyright @ 2023. All rights resesrved</h3>
                </div>
            </div>

        </>
    )
}

export default Footer

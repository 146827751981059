import React from 'react';
import chathelp from '../images/assets/chathelp.svg'
import backarrow from '../images/assets/backarrow.png'
const Chatbox = () => {
  return (
    <div className="chatbox">
      <div className='chathead'>
        <img src={chathelp} alt="" className='helpimg'/>
        <div className='chatheadtxt'>
        <h2>Wurfel IT</h2>
        <p>Customer Service Assistant</p></div>
        <div className='arrowchat'>
            <img src={backarrow}/>
        </div>
      </div>
      <div className='chatbody'>
        <div className='helloplaceholder'>
          <h1>Hello there!</h1>
        </div>
      </div>
    </div>
  );
};

export default Chatbox;

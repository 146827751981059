import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import country from './country.json';

const CountrySelector = ({ setData }) => {
  const countriesData = {
    countries: country,
  };

  const [selectedCountry, setSelectedCountry] = useState({
    value: 'United States',
    label: '+1',
  });

  useEffect(() => {
    // Set the default selected option
    setSelectedCountry({ value: 'United States', label: '+1' });
  }, []);

  const handleCountrySelect = (selectedOption) => {
    setSelectedCountry(selectedOption);
    setData((prevData) => ({
      ...prevData,
      country: selectedOption,
    }));
  };

  const countryOptions = countriesData.countries.map((country) => ({
    value: `${country.name}, ${country.phoneCode}`,
    label: (
      <div style={{fontSize:'8px'}}>
        <img
          src={require(`./flags/${country.image}.svg`)}
          alt={country.name}
          style={{ width: '20px', marginLeft: '20px' }}
        /> &nbsp; &nbsp; &nbsp; &nbsp; 
        {`${country.name} (${country.phoneCode})`}
        
      </div>
    ),
  }));

  const [errors, setErrors] = useState({});

  return (
    <>
      <Select
        id="countrySelect"
        placeholder="Select a country"
        name="countrycode"
        options={countryOptions}
        value={selectedCountry}
        onChange={handleCountrySelect}
      />
      {errors.country && <span className="error">{errors.country}</span>}
    </>
  );
};

export default CountrySelector;
